import React from 'react'
import * as S from '@styles/components/404'
import Container from '@components/Container'
import BannerComponent from '@components/page/BannerComponent'
import Footer from '@components/Footer'
import SEO from '@components/partials/SEO'

const NotFoundPage = () => {
  const component = {
    background_text: '404',
    text_content: {
      heading: 'Page Not Found',
    },
    background_color: 'standard',
    background_color_fill: 'full',
  }
  const page = {
    designations: ['is404'],
  }

  return (
    <>
      <SEO title="404" description="Page Not Found" />
      <Container className="404" page={page}>
        <S.FourOhFour>
          <article>
            <BannerComponent component={component}></BannerComponent>
          </article>
        </S.FourOhFour>
        <Footer />
      </Container>
    </>
  )
}

export default NotFoundPage
